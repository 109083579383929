
<template>
  <div class="pdfView" v-if="url" :class="{noAbsolute: !isAbsolute}">
      <i class="el-icon-close closeicon" @click="url = ''"></i>
      <pdf
      class="pdfUrl"
        :src="url"
        :page="currentPage"
        @num-pages="pageCount=$event"
        @page-loaded="currentPage=$event"
        @loaded="loadPdfHandler"
      ></pdf>
      <div class="page" v-if="pageCount>1">
        <p class="arrow" style="text-align:center">
        <!-- // 上一页 -->
        <van-tag color="#0080d6" size="large" @click="changePdfPage(0)">上一页</van-tag>
        {{currentPage}} / {{pageCount}}
        <!-- // 下一页 -->
        <van-tag color="#0080d6" size="large"  @click="changePdfPage(1)">下一页</van-tag>
      </p>
      </div>
    </div>
  
</template>

<script>
import pdf from 'vue-pdf'
export default {
  components:{pdf},
  props: {
    pdfUrl:{
      default: null,
    },
    isAbsolute:{
      default: true,
    },
  },
  watch:{
    pdfUrl(e){
        this.url = this.pdfUrl
        this.currentPage = 1
    },
  },
  computed:{
     currentInfo(){
      return this.list[this.index]
     },
     plat() {
      return this.$store.state.plat_id;
    },
  },
  data() {
    return {
      url:'',
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: 'pdf', // 文件类型
    };
  },
  mounted() {
        
  },
  methods: {

    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage (val) {
        // console.log(val)
        if (val === 0 && this.currentPage > 1) {
          this.currentPage--
          // console.log(this.currentPage)
        }
        if (val === 1 && this.currentPage < this.pageCount) {
          this.currentPage++
          console.log(this.currentPage)
        }
      },
      // pdf加载时
      loadPdfHandler (e) {
        this.currentPage = 1 // 加载的时候先加载第一页
      }
  },
};
</script>

<style  lang='scss' scoped>
 .pdfView{
  width: 100vw;
  height: calc(100vh - 64px);
  position: fixed;
  left: 0;
  top: 64px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  .pdfUrl{
    max-width: 90%;
    max-height: calc(90% - 40px);
    position: absolute;
    left: 5%;
    top: 5%;
    overflow-y: auto;
  }
  .closeicon{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: #fff;
    font-size: 30px;
  }
  .page{
    position: absolute;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    bottom: 20px;
    z-index: 10;
    text-align: center;
    color: #fff;
    cursor: pointer;
    ::v-deep .van-tag--large{
      padding: 10px 20px !important;
      font-size: 16px;
    }
  }
}
.noAbsolute{
  position: static;
  background-color: transparent;
  height: auto;
  width: 100%;
  padding-bottom: 80px;
  .pdfUrl{
    left: 0;
    top: 0;
    max-width: 100%;
    max-height: 100%;
  }
  .page{
    color: #333;
  }
}
</style>
